/* bootstrap-slider */

.slider {
  .slider-handle {
    width: 12px;
    height: 12px;
    top: 5px;
    background: $blue;
    box-shadow: none;

    &.round {
      border-radius: 12px;
    }
  }

  .slider-selection {
    background: $blue;
  }

  &.slider-horizontal,
  &.slider-vertical {
    &:hover {
      .tooltip.tooltip-main {
          opacity: 0.7 !important;
      }
      .tooltip-inner {
          font-size: 90%;
          width: auto;
      }
    }
  }

  &.slider-horizontal {
    width: 100%;

    .slider-track {
      height: 2px;
      margin-top: 0;
      background: rgba(0,0,0,.26);

      @include transition(left .18s cubic-bezier(.4,0,.2,1));
    }

    .slider-handle,
    .slider-tick {
      margin-left: -6px;
    }
  }

  &.slider-dragged {
    .slider-handle {
      width: 16px;
      height: 16px;
      top: 3px;

      &.round {
        border-radius: 16px;
      }
    }

    &.slider-horizontal {
      .slider-handle,
      .slider-tick {
        margin-left: -8px;
      }
    }
  }
}