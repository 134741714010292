.basic-page {
  background-color: #f3f5f7;

  .alert {
    text-align: center;
  }

  .header {
    margin-top: 40px;
    text-align: center;

    @media(max-width: 575px) {
      width: auto;
      padding: 0 20px;
    }

    .logo {
      width: 200px;
    }

    h3 {
      font-size: 30px;
      font-weight: 400;
      text-align: center;
      color: #57616b;

      @media(max-width: 767px) {
        font-size: 25px;
      }
    }

    p {
      font-size: 17px;
      font-weight: 300;
      text-align: center;
      margin: 20px auto 0;
      color: #61686e;

      @media(max-width: 767px) {
        font-size: 15px;
      }
    }
  }

  form {
    width: 360px;
    margin: 45px auto;

    @media(max-width: 575px) {
      width: auto;
      padding: 0 20px;
    }

    .form-group small {
      font-size: 90%;
    }

    .form-control {
      width: 100%;
      height: 52px;
      padding: 0 15px;
      outline: none;
      border-radius: 3px;
      border: 1px solid #C6C9CE;
      box-shadow: inset 0 1px 0 #D5D7DE;
      font-size: 18px;
      background: #dcdee4;

      @include transition(all .25s);

      @include placeholder {
        color: #7e7e9a;
      }

      &:focus {
        background: #fff;
        border-color: #7B97F5;

        @include placeholder {
          color: #B5B5B5;
        }
      }
    }

    .has-danger .form-control {
      border: 1px solid #d9534f;
      background: #e8e1e1;
      box-shadow: inset 0 1px 0 #e8e1e1;
    }

    .terms, .options {
      margin-top: 15px;
      font-size: 13px;
      color: #666;

      input[type="checkbox"] {
        font-size: 20px;
        margin-right: 6px;
      }
    }

    .options {
      font-size: 14px;
    }

    .form-action {
      margin-top: 30px;

      button {
        padding: 14px 23px; 
      }
    }

    .form-bottom {
      margin-top: 15px;
      color: #768298;

      a {
        margin-left: 5px;
      }
    }
  }
}