
.page-hero {
    height: 330px;
    background-image: url('../assets/images/unsplash/benz.jpg');
    background-size: cover;
    background-position: center center;
    position: relative;

    @media(max-width: 991px) {
        height: 300px;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.2);
    }
}

.page-header {
    height: 330px;
    background-image: linear-gradient(310deg,#272B53 0,#4f7296 90%);

    &.dark {
        background-image: linear-gradient(310deg,#5f5f5f 0,#a0a0a0 90%);
    }

    section {
        padding-top: 120px;
        padding-bottom: 30px;

        @media(max-width: 991px) {
            padding-top: 100px;
        }

        @media(max-width: 767px) {
            padding-top: 90px;
            text-align: center;
        }

        h1 {
            color: #fff;
            line-height: 44px;
            font-size: 33px;
            font-weight: 400;

            @media(max-width: 991px) {
                font-size: 30px;
            }

            @media(max-width: 767px) {
                font-size: 27px;
            }
        }

        h2 {
            color: #fff;
            line-height: 34px;
            font-size: 28px;
            font-weight: 400;

            @media(max-width: 991px) {
                font-size: 25px;
            }

            @media(max-width: 767px) {
                font-size: 22px;
            }
        }

        p, a {
            color: #fdfdfd;
        }
    }
}

.page-content {
    background: #f3f3f5;

    @media(max-width: 767px) {
        margin-bottom: -120px;
    }

    > .container {
        padding: 50px 80px;
        border-radius: 5px;
        background: #fff;
        position: relative;
        top: -140px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);

        @media(min-width: 1200px) {
            width: 900px;
        }

        &.sign-form {
            @media(min-width: 1200px) {
                width: 520px;
            }
        }

        @media(max-width: 991px) {
            padding: 30px 50px 50px;
        }

        @media(max-width: 767px) {
            padding: 20px 20px 50px;
            top: -160px;
        }
    }

    .page-content-title {
        text-align: center;
        font-weight: 300;
        margin-bottom: 35px;
        font-size: 35px;
        line-height: 25px;

        @media(max-width: 767px) {
            font-size: 28px;
        }

        img {
            display: block;
            margin: 35px auto 15px;
        }

        small {
            margin-top: 15px;
            font-size: 18px;
            color: #75779a;
            display: block;
        }
    }

    .page-content-body {
        margin-top: 40px;

        p {
            font-size: 15px;
            color: #6b6b6b;
            line-height: 29px;
            margin-bottom: 20px;
        }

        h2 {
            margin: 45px 0 20px;
            font-size: 23px;
        }
    }

    .quote-wrapper {
        margin: 45px 0;

        .quote {
            position: relative;
            font-size: 20px;
            line-height: 30px;
            padding-left: 45px;
            width: 95%;

            @media(max-width: 767px) {
                font-size: 18px;
            }

            span {
                font-family: Georgia;
                font-size: 75px;
                position: absolute;
                left: 0;
                color: #94a7d4;
                top: 10px;
            }
        }

        .author {
            padding-left: 40px;
            margin-top: 15px;
            font-size: 15px;
            color: #656565;
        }
    }
}

.page-map {
    position: relative;

    #map {
        height: 520px;
        width: 100%;
    }

    .location {
        background: #fff;
        padding: 45px 30px;
        max-width: 300px;
        position: absolute;
        z-index: 10;
        top: 20px;
        border-radius: 3px;
        box-shadow: 0 1px 2px rgba(0,0,0,0.2);

        @media(max-width: 991px) {
            max-width: 260px;
        }

        h5 {
            margin-bottom: 20px;
            color: #356c9d;
        }
    }
}

.page-nav {
    height: 65px;

    .features {
        text-align: center;
        border-bottom: 1px solid #ddd;

        &.fixed {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            background: #fff;
            z-index: 9999;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

            @include animationDuration(.5s);
        }

        li {
            display: inline-block;

            &:nth-child(1) a {
                @include animationDelay(.35s);
            }

            &:nth-child(2) a {
                @include animationDelay(.5s);
            }

            &:nth-child(3) a {
                @include animationDelay(.65s);
            }

            &:nth-child(4) a {
                @include animationDelay(.8s);
            }
        }

        .nav-link {
            display: inline-block;
            padding: 22px;
            height: 65px;
            vertical-align: top;
            margin: 0 10px;
            font-size: 15px;
            color: #333;
            position: relative;
            overflow: hidden;

            @media(max-width: 991px) {
                font-size: 14px;
            }

            &.active {
                color: #ea9538;

                &:after {
                    bottom: 0;
                    background: #f7b063;
                }
            }

            &:after {
                content: '';
                position: absolute;
                height: 3px;
                bottom: -3px;
                left: 0;
                width: 100%;

                @include transition(all .15s linear);
            }

            &:hover,
            &:focus {
                opacity: 1;
                background: none;

                &:after {
                    bottom: 0;
                    background: #fdd0ad;
                }
            }
        }
    }
}

.page-nav-item {
    padding-top: 40px;

    h2 {
        margin: 0 0 20px !important;
    }
}