.home-page {
  .footer {
    padding: 100px 0 35px;

    @media(max-width: 767px) {
      padding: 60px 0 35px;
    }
  }
}

.home-hero, .home-content {
  background-size: cover;
  background-position: center 0px;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  @media(max-width: 1024px) {
    background-attachment: inherit;
  }

  @media(max-width: 991px) {
    height: auto;
    padding-bottom: 100px;
  }

  .map, .overlay {
    position: absolute;
    right: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
  }

  .container {
    position: relative;
    z-index: 10;
    margin-top: 220px;

    @media(max-width: 991px) {
      margin-top: 150px;
    }
  }

  .overlay {
    background: linear-gradient(-45deg,rgba(44,62,80,0.5) 0,rgba(44,62,80,0.8) 80%)
    // background: linear-gradient(-45deg,rgba(44,62,80,.4) 0,rgba(44,62,80,.7) 80%);
  }
}

.home-hero {
  h2 {
    color: #fff;
    font-size: 48px;
    letter-spacing: .5px;
    font-family: $sourceSans;
    font-weight: 300;
    line-height: 54px;
    width: 800px;
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.11);

    @include animationDelay(.1s);

    @media(max-width: 991px) {
      font-size: 43px;
      line-height: 50px;
      width: auto;
    }

    @media(max-width: 767px) {
      text-align: center;
      font-size: 38px;
    }
  }

  p {
    color: rgba(255, 255, 255, 0.8);
    margin-top: 40px;
    width: 480px;
    font-size: 16px;
    line-height: 25px;

    @include animationDelay(.4s);

    @media(max-width: 991px) {
      width: auto;
    }

    @media(max-width: 767px) {
      text-align: center;
      font-size: 15px;
      line-height: 24px;
    }
  }

  .actions {
    margin-top: 50px;

    @include animationDelay(.4s);

    @media(max-width: 767px) {
      text-align: center;
    }

    .btn-pill {
      font-weight: 600;
      padding: 13px 44px 16px;

      &:active {
        border-color: #3b6b23;
      }
    }
  }
}

.home-content {
  .home-form {
    position: relative;
    margin-bottom: 25px;

    input[type="text"] {
      border: 1px solid #D4DAE0;
      width: 100%;
      padding: 14px 80px;
      border-radius: 50px;
      outline: none;
      font-size: 18px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

      @include transition(all .2s);

      @include placeholder {
        color: #A1A1A1;
      }

      @media(max-width: 767px) {
        font-size: 16px;
        padding: 15px 60px;
      }

      &:focus {
        border-color: #A4B8CB;
      }
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border: none;
      padding: 5px 20px;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;

      i {
        font-size: 25px;
      }
    }

    .icon {
      position: absolute;
      font-size: 25px;
      top: 8px;
      bottom: 8px;
      left: 30px;
      color: #9cabcc;

      @media(max-width: 767px) {
        left: 20px;
      }
    }

    .actions {
      text-align: center;
      margin: 25px 0;

      .btn {
        margin: 0 5px;
      }
    }
  }

  .results-form {

    .search-container {
      position: relative;

      input[type="text"] {
        border: none;
        border-bottom: 1px solid transparent;
        width: 100%;
        padding: 10px 90px 10px 15px;
        border-radius: 2px;
        outline: none;
        background: none;

        @include transition(all .2s);

        @include placeholder {
          color: #A1A1A1;
        }
      }

      .search-controls {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        padding: 0 10px;

        button {
          border: none;
          background: 0 0;
          cursor: pointer;
          outline: none;
          padding: 0 10px;
          margin: 5px 0;

          &:first-child {
            border-right: 1px solid #eee;
          }

          i {
            font-size: 20px;
            color: #999;
          }

          &:hover,
          &:focus {
            i {
              color: #025aa5
            }
          }
        }
      }

      .icon {
        position: absolute;
        font-size: 20px;
        top: 8px;
        bottom: 8px;
        left: 24px;
        color: #9cabcc;

        @media(max-width: 767px) {
          left: 20px;
        }
      }
    }

    .range-container {
      padding: 0 15px 10px;
    }
  }

  .list-group {
    -webkit-flex: 1 1;
    -ms-flex: 1 1;
    flex: 1 1;
    overflow-y: auto;
    overflow-x: hidden;

    .list-group-item {
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
      padding: 10px 15px;
      -webkit-flex: none;
      -ms-flex: none;
      flex: none;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      background: none;

      &:first-child {
        border-top: 0;
      }

    }

    a.list-group-item {
      cursor: pointer;
      font-size: 13px;

      &:hover {
        background-color: #f7f7f9;
      }
    }
  }

  .home-text {
    text-align: center;

    @media(max-width: 767px) {
      top: calc(50% - 190px);
    }
  }

  h1.title {
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;

    @include animationDelay(.1s);
  }

  p.subtitle {
    color: #fff;
    text-align: center;
    font-size: 26px;
    font-weight: 300;
    line-height: 30px;
    margin-top: 17px;
    width: 60%;
    margin: 30px auto;

    @include animationDelay(.4s);

    @media(max-width: 991px) {
      font-size: 30px;
      width: 90%;
    }

    @media(max-width: 767px) {
      width: 100%;
      font-size: 28px;
      line-height: 38px;
    }
  }

  .widget-pane {
    display: none;
    position: absolute;
    bottom: 90px;
    left: 10px;
    width: 408px;
    top: 10px;

    @media(max-width: 767px) {
      width: auto;
      right: 10px;
      top: 60px;
      max-height: 75px;
      transition: max-height .25s ease-in;

      &.expanded {
        max-height: 100%;
        transition: max-height .25s ease-out;
      }
    }

    &-content {
      box-shadow: 0 1px 2px rgba(0,0,0,0.2);
      background: #fff;
      border-radius: 2px;

      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: left;
      overflow: visible;
      position: absolute;
      height: 100%;
      width: 100%;
    }

    &-toggle {
      display: none;
      position: absolute;
      bottom: -22px;
      padding: 0rem .5rem;
      margin: auto;
      right: 40px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      color: #fff;
      opacity: 0.7;

      // &:before {
      //   content: "\f123";
      // }

      @media(max-width: 767px) {
        display: block;
      }
    }
  }
}

.home-intro {
  margin-top: 90px;
}

.index-blog-grid {
  margin-top: 150px;

  header {
    font-size: 18px;
    margin-bottom: 30px;
    position: relative;
    text-align: center;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background: #E9E9E9;
      top: 11px;
    }

    h4 {
      position: relative;
      top: -8px;
      padding: 0 40px;
      background: #fff;
      color: #97A8B8;
      display: inline-block;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 1.8px;
      word-spacing: 1.6px;
      font-weight: 600;
    }
  }

  section {
    margin-top: 50px;

    .row {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .post {
      position: relative;
      text-decoration: none;
      display: block;
      width: 100%;
      height: 270px;
      overflow: hidden;

      &:hover {
        .bg {
          @include transform(scale3d(1.12, 1.12, 1.12));
        }
      }

      .bg {
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        @include transform(scale3d(1, 1, 1));
        @include transition(all .4s);

        &:after {
          position: absolute;
          top: 50%;
          bottom: 0;
          left: 0;
          right: 0;
          content: '';
          background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(9, 24, 42, 0.8)));
          background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(9, 24, 42, 0.8) 100%);
          background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), rgba(9, 24, 42, 0.8));
          background: -o-linear-gradient(top, rgba(255, 255, 255, 0), rgba(9, 24, 42, 0.8));
        }
      }

      p {
        color: #fff;
        z-index: 99;
        position: absolute;
        bottom: 15px;
        left: 20px;
        width: 60%;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.index-poem {
  height: 500px;
  background-image: url('../assets/images/unsplash/memory.jpg');
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 10;
  *margin-top: 85px;

  @media(max-width: 767px) {
    height: auto;
  }

  .quote-wrapper {
    width: 510px;
    height: 390px;
    background: #54657b;
    position: absolute;
    right: 13%;
    bottom: -60px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    padding: 60px 70px;

    @media(max-width: 767px) {
      width: auto;
      position: relative;
      left: 0;
      right: 0;
      height: auto;
      top: 0;
      padding: 50px 40px;
    }
  }

  .logo {
    max-width: 160px;
  }

  p {
    margin-top: 25px;
    color: #fff;
    font-size: 18px;
    line-height: 27px;
    font-style: italic;

    @media(max-width: 767px) {
      font-size: 16px;
    }
  }

  .title {
    color: #ffffff;
    font-size: 28px;
  }

  .author {
    color: #cfdcec;
    margin-top: 20px;
    font-size: 16px;
  }
}

.index-features-intro {
  padding-top: 70px;

  @media(max-width: 767px) {
    padding-top: 50px;
  }

  .header {
    text-align: center;

    h2 {
      font-size: 26px;
      letter-spacing: .1px;
      font-weight: 400;

      @media(max-width: 767px) {
        font-size: 23px;
      }
    }

    p {
      color: #5A6C90;
      margin-top: 15px;
      font-size: 16px;

      @media(max-width: 767px) {
        font-size: 15px;
      }
    }
  }

  .feature {
    text-align: center;
    margin-top: 50px;

    @media(max-width: 767px) {
      text-align: center;
    }

    h3 {
      color: #2F4166;
      font-size: 18px;
      margin-top: 20px;

      .badge-new {
        background-image: linear-gradient(-90deg, #609CFF 0%, #8D7FFF 100%);
        text-transform: uppercase;
        font-size: 12px;
        color: #fff;
        border-radius: 4px;
        padding: 3px 8px;
        letter-spacing: 0.5px;
        font-weight: bold;
        position: relative;
        top: -3px;
        left: 7px;
      }
    }

    p {
      color: #7080A2;
      line-height: 25px;
      font-size: 15px;
      margin-top: 15px;
      padding: 15px;

      @media(max-width: 767px) {
        width: auto;
      }
    }
  }
}

.index-clients {
  padding-top: 100px;
  text-align: center;

  header {
    font-size: 18px;
    margin-bottom: 30px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background: #E9E9E9;
      top: 11px;
    }

    h4 {
      position: relative;
      top: -8px;
      padding: 0 40px;
      background: #fff;
      color: #97A8B8;
      display: inline-block;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 1.8px;
      word-spacing: 1.6px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .img-responsive {
    margin: 0 auto;
  }
}